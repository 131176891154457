<mat-toolbar color="primary" class="navbar" *ngIf="isLoggedIn() else notLoggedIn">
    <span class="navbar-left">
        <a class="navbar-brand" [routerLink]="['/answers']">SRAM</a>
        <span class="sw-version"><app-version></app-version></span>
    </span>
    <div class="" *ngIf="(user$ | async) as user">
        <button mat-button [routerLink]="['/admin/users']" *ngIf="canAdmin(user)">
            Admin
        </button>
        <button mat-button [matMenuTriggerFor]="accountMenu">
            Account <mat-icon>expand_more</mat-icon>
        </button>
        <mat-menu #accountMenu="matMenu">
            <button mat-menu-item [routerLink]="['/account']">
                <mat-icon>settings</mat-icon> Details & Settings
            </button>
            <button mat-menu-item (click)="logout()">
                <mat-icon>logout</mat-icon> Logout
            </button>
        </mat-menu>
    </div>
</mat-toolbar>

<ng-template #notLoggedIn>
  <mat-toolbar color="primary" class="navbar">
    <span class="navbar-left">
        <a class="navbar-brand" [routerLink]="['/']">SRAM</a>
        <span class="sw-version"><app-version></app-version></span>
    </span>
  </mat-toolbar>
</ng-template>
